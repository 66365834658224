import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Anchor from '../../components/Anchor'
import Accordion from '../../components/Accordion'
import AccordionPanel from '../../components/AccordionPanel'
import SideBySide from '../../components/SideBySide'
import LeadForm from '../../components/Form/lead'
import { TABLET } from '../../constants/breakpoints'
import { POSTAL_URL_BASE, GROUP_URL_BASE } from '../../../env'
import { METADATA } from '../../config/faqs'

const Heading = styled.h1`
  font-size: 1.5rem !important;
  line-height: 180% !important;
  text-transform: uppercase;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.25rem !important;
    text-align: center;
  }
`

const Sidekick = () => (
  <div>
    <Heading>Get a quote in minutes</Heading>
    <p>
      RMA Group Life Cover provides your employees with a package of great value
      protection benefits for their family.
    </p>
    <LeadForm postalUrl={POSTAL_URL_BASE} type="inline" />
  </div>
)

const Page = () => (
  <Layout {...METADATA}>
    <SideBySide sidebar={<Sidekick />}>
      <Heading>Frequently Asked Questions</Heading>
      <Accordion>
        <AccordionPanel title="Why should I consider Group Cover?">
          <p>
            Your employees are often family breadwinners. If tragedy strikes,
            their dependents may be left destitute. As their employer, you may
            be left with a moral responsibility towards their families. The
            chances are that many of your staff do not have life cover, or where
            they have it, it is insufficient. Group Cover is typically much
            cheaper than the cover your staff might have taken out for
            themselves. So, if you can get them insured at a lower cost, they
            might be able to make substantial savings, even where you pass the
            cost on to them. Also, some of your staff may not be able to get
            cover for themselves due to existing health issues. With Group
            Cover, as long as you have at least 5 employees, everyone can get
            covered, regardless of their state of health.
          </p>
        </AccordionPanel>

        <AccordionPanel title="What can I expect to pay for Group Cover?">
          <p>
            Cover is generally much cheaper than what your employees could get
            themselves. We recommend getting a sample quote by{' '}
            <Anchor href={GROUP_URL_BASE}>clicking here</Anchor> to see for
            yourself – it takes seconds.
          </p>
        </AccordionPanel>

        <AccordionPanel title="How does the product work?">
          <p>
            RMA Group Life Cover offers 3 different benefits – Life, Disability
            and Family Funeral Cover. You, as the employer, can choose all 3, or
            any combination of benefits – it’s up to you. You can also tailor
            the amount of cover per benefit to suit your budget.
          </p>
        </AccordionPanel>

        <AccordionPanel title="Is there a waiting period before claims will be paid out?">
          <p>
            There is no waiting period for life and funeral claims. There is a
            6-month waiting period on disability claims, however, where cover
            will be limited to accidental causes. Waiting periods may be waived
            where your RMA Group Life Cover policy is replacing an existing
            group cover policy.
          </p>
        </AccordionPanel>

        <AccordionPanel title="How quickly are my employees covered?">
          <p>
            As soon as you click the &lsquo;Finish&rsquo; button on the online
            application process, your employees are covered. The cover is
            immediate, even though you haven&rsquo;t paid your first premium
            yet.
          </p>
        </AccordionPanel>

        <AccordionPanel title="How long does the whole process take from start to when my staff are covered?">
          <p>
            If you have all the employee information ready (i.e. name, gender,
            birth date, salary, ID/passport number and employment start date),
            the entire process will take less than 15 minutes – it’s entirely
            online, no paperwork.
          </p>
        </AccordionPanel>

        <AccordionPanel title="Is our organisation not too small to take out Group Cover?">
          <p>
            There are no minimum requirements to take out an RMA Group Life
            Cover.
          </p>
        </AccordionPanel>
      </Accordion>
    </SideBySide>
  </Layout>
)

export default Page
