export const METADATA = {
  route: '/faqs',
  imagePath: 'social-faqs.jpg',
  pageType: 'website',
  title: 'Frequently asked questions',
  description: `
    Get one step closer to protecting your employees.
    Find out what people frequently ask us about
    RMA Group Life Cover.
  `,
}
